@function getBrand() {
    @return $brand;
}

@function getBrandColor($key) {
    @return getValue($brandColor, $key);
}

@function getIconVar($key) {
    @return getValue($iconVars, $key);
}

@function getNeutralColors($key) {
    @return getValue($neutral, $key);
}

@function getUtilityColors($key) {
    @return getValue($utility, $key);
}

@function getTextColors($key) {
    @return getValue($text, $key);
}

@function getThemeColors($key) {
    @return getValue($themeColors, $key);
}

@function getShadows($key) {
    @return getValue($shadows, $key);
}

@function getOverlays($key) {
    @return getValue($overlays, $key);
}

@function getFontSizes($key) {
    @return getValue($fontSizes, $key);
}

@function getFontFamily($key) {
    @return getValue($fontFamilies, $key);
}

@function getLineHeight($key) {
    @return getValue($lineHeight, $key);
}

@function getFontWeights($key) {
    @return getValue($fontWeights, $key);
}

@function getFontSrc($key) {
    @return getValue($fontSrc, $key);
}

@function getBreakpoints($key) {
    @return getValue($breakpoints, $key);
}

@function getSizes($key) {
    @return getValue($sizes, $key);
}

// the unit spacing is 8px
@function getSpacingValues($value) {
    @if isNumber($value) {
        @return $minimunUnit * $value;
    } @else if $value == auto {
        @return $value;
    }

    @error "`#{$value}` in not Number.";
    @return null;
}

// the unit value is 4px
@function getSpacingValue($value) {
    @if isNumber($value) {
        @return $minimunUnitValue * $value;
    } @else if $value == auto {
        @return $value;
    }

    @error "`#{$value}` in not Number.";
    @return null;
}

@function getValue($atom, $key) {
    @if map-has-key($atom, $key) {
        @return map-get($atom, $key);
    }

    @error "Unknown `#{$key}` in `#{$atom}`.";
    @return null;
}

@function isNumber($value) {
    @return type-of($value) == 'number';
}

// on temporary basis added
@function getRGBAColors($key) {
    @return getValue($utility, $key);
}

@function getPrimaryColors($key) {
    @return getValue($primary, $key);
}

@function getBlueColors($key) {
    @return getValue($blue, $key);
}

@function getAccentColors($key) {
    @return getValue($accent, $key);
}

@function getRedColors($key) {
    @return getValue($red, $key);
}

@function getGreenColors($key) {
    @return getValue($green, $key);
}

@function getYellowColors($key) {
    @return getValue($yellow, $key);
}

@function getMonetizationColors($key) {
    @return getValue($monetization, $key);
}

@function getAlertColors($key) {
    @return getValue($alert, $key);
}

$brand: var(--brand);

$primary: (
        primaryBase: var(--primaryBase),
        primaryLight1: var(--primaryLight1),
        primaryLight3: var(--primaryLight3),
        primaryLight2: var(--primaryLight2),
        primaryLight4: var(--primaryLight4),
        primaryLight5: var(--primaryLight5),
        primaryLight6: var(--primaryLight6),
        primaryDark1: var(--primaryDark1),
        primaryDark2: var(--primaryDark2)
);

$blue: (
        blueBase: var(--blueBase),
        blueLight1: var(--blueLight1),
        blueLight2: var(--blueLight2),
        blueLight3: var(--blueLight3),
        blueLight4: var(--blueLight4),
        blueLight5: var(--blueLight5),
        blueLight6: var(--blueLight6),
        blueLight7: var(--blueLight7),
        blueLight8: var(--blueLight8),
        blueDark1: var(--blueDark1),
        blueDark2: var(--blueDark2),
        blueDark3: var(--blueDark3)
);

$black: (
        blackBase: var(--blackBase),
        blackLight1: var(--blackLight1),
        blackLight2: var(--blackLight2),
        blackLight3: var(--blackLight3),
);

$accent: (
        accentBase: var(--accentBase),
        accentLight1: var(--accentLight1),
        accentLight2: var(--accentLight2),
        accentLight3: var(--accentLight3),
        accentLight4: var(--accentLight4),
        accentLight5: var(--accentLight5),
        accentLight6: var(--accentLight6),
        accentDark1: var(--accentDark1),
        accentDark2: var(--accentDark2)
);

$yellow: (
        yellowBase: var(--yellowBase),
        yellowLight1: var(--yellowLight1),
        yellowLight2: var(--yellowLight2),
        yellowLight3: var(--yellowLight3),
        yellowLight4: var(--yellowLight4),
        yellowDark1: var(--yellowDark1),
        yellowDark2: var(--yellowDark2),
);

$red: (
        redBase: var(--redBase),
        redLight1: var(--redLight1),
        redLight2: var(--redLight2),
        redLight3: var(--redLight3),
        redLight4: var(--redLight4),
        redLight5: var(--redLight5),
        redDark1: var(--redDark1),
        redDark2: var(--redDark2),
        redDark3: var(--redDark3)
);

$green: (
        greenBase: var(--greenBase)
);

$monetization: (
        monetizationBase: var(--monetizationBase),
        monetizationLight1: var(--monetizationLight1),
        monetizationLight2: var(--monetizationLight2),
        monetizationLight3: var(--monetizationLight3),
        monetizationLight4: var(--monetizationLight5),
        monetizationLight5: var(--monetizationLight4),
        monetizationDark1: var(--monetizationDark1),
        monetizationDark2: var(--monetizationDark2),
        monetizationRedesignLight1: var(--monetizationRedesignLight1),
        monetizationRedesignLight2: var(--monetizationRedesignLight2),
        monetizationRedesignLight3: var(--monetizationRedesignLight3),
        monetizationRedesignLight4: var(--monetizationRedesignLight4),
        monetizationRedesignLight5: var(--monetizationRedesignLight5),
        monetizationRedesignDark1: var(--monetizationRedesignDark1)
);

$brandColor: (
        primary: var(--brandPrimary),
        primaryDark: var(--brandPrimaryDark),
        primaryLight: var(--brandPrimaryLight),
        accent: var(--brandAccent),
        accentDark: var(--brandAccentDark),
        accentLight: var(--brandAccentLight),
        primaryGrey: var(--brandPrimaryGrey),
);

$neutral: (
        black: var(--black),
        blackGradients: var(--blackGradients),
        blackBackground: var(--blackBackground),
        blackDividers: var(--blackDividers),
        blackDisabled: var(--blackDisabled),
        blackHintText: var(--blackHintText),
        blackSecondaryText: var(--blackSecondaryText),
        blackIcons: var(--blackIcons),
        blackText: var(--blackText),
        blackLight2:var(--neublackLight2),
        white: var(--white),
        whiteGradients: var(--whiteGradients),
        whiteDividers: var(--whiteDividers),
        whiteDisabled: var(--whiteDisabled),
        whiteHintText: var(--whiteHintText),
        whiteHiglightBackground: var(--whiteHiglightBackground),
        whiteSecondaryText: var(--whiteSecondaryText),
        whiteIcons: var(--whiteIcons),
        whiteText: var(--whiteText),
        background: var(--background),
        greyBorder: var(--greyBorder),
        lightGrey: var(--lightGrey),
        blackDividers2: var(--blackDividers2),
        background2: var(--background2),
        blackBackground2: var(--blackBackground2),
        blackBackground3: var(--blackBackground3),
        blackBackground4: var(--blackBackground4),
        redDisbabled: var(--redDisbabled),
        blackOpaque: var(--blackOpaque),
        disabled: var(--disabled),
  // new ashish
        backgroundDark: var(--backgroundDark),
        gray: var(--gray)
);

$utility: (
        error: var(--utilError),
        errorDark: var(--utilErrorDark),
        errorLight: var(--utilErrorLight),
        facebook: var(--facebook),
        messenger: var(--messenger),
        whatsapp: var(--whatsapp),
        google: var(--google),
        monetization: var(--utilMonetization),
        monetizationDark: var(--utilMonetizationDark),
        monetizationLight: var(--utilMonetizationLight),
        infoBadge: var(--infoBadge),
        promotedBadge: var(--promotedBadge),
        chatPrimary: var(--chatPrimary),
        chatPrimary1: var(--chatPrimary1),
        chatSecondary: var(--chatSecondary),
        disabled: var(--disabledUtility),
        disabledText: var(--disabledText),
        label: var(--utilLabel),
        labelGhost: var(--labelGhost),
        goodAlert: var(--goodAlert),
        veryGoodAlert: var(--veryGoodAlert),
        worstAlert: var(--worstAlert),
        chatSecondary1: var(--chatSecondary1),
        chatLabelBackground: var(--chatLabelBackground),
        offerHighlight: var(--offerHighlight),
        pinToTopBadge: var(--pinToTopBadge),

        // rgba color
        primarybase15: var(--primarybase15),
        primarybase04: var(--primarybase04),
        primarybase19: var(--primarybase19),
        primarybase80: var(--primarybase80),
        blueBase29: var(--blueBase29),
        blueBase25: var(--blueBase25),
        blueBase50: var(--blueBase50),
        blueLight447: var(--blueLight447),
        disabled40: var(--disabled40),
        error20: var(--error20),
        infoBadge50: var(--infoBadge50),
        primaryBaseText15: var(--primaryBaseText15),
        primaryLight1Text80: var(--primaryLight1Text80),
        blackText70: var(--blackText70),
        accent50: var(--accent50),
        blackText50: var(--blackText50),
        blackText20: var(--blackText20),
        black20: var(--black20),
        lightGrey60: var(--lightGrey60),
        accentLight50: var(--accentLight50),
        primaryGrey70: var(--primaryGrey70),
        brandPrimary36: var(--brandPrimary36),
        black25: var(--black25),
        black24: var(--black24),
        black50: var(--black50),
        white87: var(--white87),
        black30: var(--black30)

);

$alert: (
        alertSuccess:var(--alertSuccess),
        alertError:var(--alertError)
);

$text: (
        primaryBaseText: var(--primaryBaseText),
        primaryLight1Text: var(--primaryLight1Text),
        primaryLight2Text: var(--primaryLight2Text),
        primaryLight3text: var(--primaryLight3text),
        primary: var(--textPrimary)
);

$themeColors: (
        valuationCarousalDisabledBg: var(--valuationCarousalDisabledBg),
        valuationCarousalSelectedBg: var(--valuationCarousalSelectedBg),
        valuationProgressColor: var(--valuationProgressColor),
        valuationPriceBg: var(--valuationPriceBg),
        valuationPriceBorder: var(--valuationPriceBorder),
        bookingMainHeading: var(--bookingMainHeading),
        bookingSelectedLocation: var(--bookingSelectedLocation),
        bookingTerms: var(--bookingTerms),
        bookingSelectedDateBorder: var(--bookingSelectedDateBorder),
        bookingConfirmSubtitle: var(--bookingConfirmSubtitle),
        moneBusinessPackageBanner: var(--moneBusinessPackageBanner),
        monePackagePropositionBanner: var(--monePackagePropositionBanner),
        bottomFooterBg: var(--bottomFooterBg),
        topFooterBg: var(--topFooterBg),
        inputBorder: var(--inputBorder),
        headerBg: var(--headerBg),
        notificationBg: var(--notificationBg),
        notificationText: var(--notificationText),
        bulletDisabledBg: var(--bulletDisabledBg),
        bulletBg: var(--bulletBg),
        pageHeadlineBackgroundColor: var(--pageHeadlineBackgroundColor),
        howToSellStepsColor: var(--howToSellStepsColor),
        cityOfTheCityListInLocations: var(--cityOfTheCityListInLocations),
        buttonInLocationsMobileView: var(--buttonInLocationsMobileView),
        locationInfo: var(--locationInfo),
        progressBulletFilled: var(--progressBulletFilled),
        progressBulletBlinker: var(--progressBulletBlinker),
        progressBulletDisabled: var(--progressBulletDisabled),
        mapPinInfoBg: var(--mapPinInfoBg),
        mapPinInfoText: var(--mapPinInfoText),
        grayYellowMap: var(--grayYellowMap),
        grayYellowMapDark: var(--grayYellowMapDark),
        blueLight3primaryLight3: var(--blueLight3primaryLight3),
        blueLight4primaryLight4: var(--blueLight4primaryLight4),
        blueDark1blackBase: var(--blueDark1blackBase)
);

$breakpoints: (
    mobile: 320px,
    phablet: 540px,
    tablet: 960px,
    desktop: 1024px,
    desktopLarge: 1280px,
    desktopXL: 1620px
);

$sizes: (
    mobileWidth: 100%,
    phabletWidth: 100%,
    tabletWidth: 100%,
    desktopWidth: 100%,
    desktopMinWidth: 1024px,
    desktopLargeWidth: 1280px,
    desktopXLWidth: 1620px
);

$minimunUnit: 8px;
$minimunUnitValue: 4px;

$fontFamilies: (
    face: Arial,
    body: (Arial, Helvetica, sans-serif),
    customLTR: var(--customLTRFont),
    customRTL: var(--customRTLFont)
);

$fontSizes: (
    display: 45px,
    headline: 34px,
    title: 24px,
    subtitle: 20px, //subheader
    body2: 16px,
    heading: 18px,
    body1: 14px,
    footnote: 12px, //caption
    caption: 10px, //small
    badge: 8px
);

$lineHeight: (
    display: 48px,
    headline: 40px,
    title: 32px,
    heading: 24px,
    subtitle: 24px,
    body2: 24px,
    body1: 20px,
    footnote: 18px,
    caption: 16px,
    caption2: 12px,
    badge: 10px
);

$fontWeights: (
    bold: 700,
    medium: 500,
    bolder: 600,
    regular: 400
);

$newShadows: (
    small: 0 1px 4px 0 rgba(55, 70, 95, 0.12),
    medium: 0 0 8px 0 rgba(55, 70, 95, 0.12),
    large: 0 10px 20px 0 rgba(55, 70, 95, 0.12),
    xLarge: 0 20px 40px 0 rgba(0, 0, 0, 0.12),
    fab: (0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)),
    newWidget:  0 4px 7px 3px rgba(0, 0, 0, 0.2),
    newWidgetFooter: 0 -3px 4px 0 rgba(0,0,0,.04),
    insetShadow: inset 0px 3px 6px rgba(55, 70, 95, 0.12),
    valuationFlowCTA: 3px 0px 6px rgba(55, 70, 95, 0.12)
);
$oldShadows: (
    level1: (0 1px 4px 0 rgba(0, 0, 0, 0.1)),
    level2: (0 2px 8px 0 rgba(0, 0, 0, 0.15)),
    level3: (0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24)),
    level4: (0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3)),
    level5: (0 1px 0 0 rgba(0, 47, 52, .2)),
    level6: (0 1px 3px 0 rgba(0, 47, 52, 0.2), 0 1px 3px 0 rgba(0, 47, 52, 0.2))
);

$shadows: map-merge($oldShadows, $newShadows);

$overlays: (
    normal: rgba(0, 0, 0, 0.8),
    disabled: rgba(255, 255, 255, 0.5)
);

$iconVars: (
	icomoon-font-family: "olx-icons",
	icomoon-font-src: (
		url('../public/fonts/olx-icons.woff2') format('woff2'),
		url('../public/fonts/olx-icons.woff') format('woff'),
		url('../public/fonts/olx-icons.eot') format('embedded-opentype'),
		url('../public/fonts/olx-icons.ttf') format('truetype'),
		url('../public/fonts/olx-icons.svg') format('svg')
	),

    icon-download: "\e900",
    icon-arrow-top-left: "\e902",
    icon-SMS: "\e904",
    icon-more: "\e905",
    icon-circle_stroke: "\e906",
    icon-Approved: "\e908",
    icon-CreditCard: "\e909",
    icon-Cash: "\e90a",
    icon-OK: "\e90b",
    icon-Tips: "\e90c",
    icon-ArrowRight: "\e910",
    icon-ArrowLeft: "\e911",
    icon-ArrowDown: "\e912",
    icon-ArrowUp: "\e913",
    icon-Cross: "\e917",
    icon-Menu: "\e91c",
    icon-Location: "\e91e",
    icon-Crosshairs: "\e91f",
    icon-Classes: "\e920",
    icon-Electronics: "\e922",
    icon-FavoriteOff: "\e923",
    icon-FavoriteOn: "\e924",
    icon-Facebook: "\e925",
    icon-FacebookProfile: "\e927",
    icon-FacebookProfileValidated: "\e928",
    icon-SendMessage: "\e92a",
    icon-GoogleProfile: "\e92b",
    icon-Zoom: "\e92c",
    icon-GoogleProfileValidated: "\e92d",
    icon-MakeAnOffer: "\e92e",
    icon-Flag: "\e92f",
    icon-ShareIt: "\e930",
    icon-PhoneProfile: "\e931",
    icon-Back: "\e932",
    icon-PhoneProfileValidated: "\e933",
    icon-Whatsapp: "\e935",
    icon-Messenger: "\e936",
    icon-Home: "\e938",
    icon-Industries: "\e939",
    icon-Instruments: "\e93a",
    icon-Jobs: "\e93b",
    icon-Games: "\e93d",
    icon-Animals: "\e93e",
    icon-Farming: "\e941",
    icon-Fashion: "\e942",
    icon-RealEstate: "\e943",
    icon-Services: "\e944",
    icon-ForSale: "\e945",
    icon-Smartphones: "\e947",
    icon-Sports: "\e948",
    icon-Furnitures: "\e949",
    icon-Vehicles: "\e94c",
    icon-Babies: "\e94d",
    icon-Exit: "\e94e",
    icon-Edit: "\e94f",
    icon-Trash: "\e951",
    icon-Settings: "\e952",
    icon-Search: "\e953",
    icon-Notifications: "\e954",
    icon-Photo: "\e956",
    icon-Hot: "\e957",
    icon-View: "\e958",
    icon-Chat: "\e959",
    icon-User: "\e95a",
    icon-UserCheck: "\e95b",
    icon-Friends: "\e95d",
    icon-Refresh: "\e961",
    icon-ErrorHaired: "\e962",
    icon-OKHaired: "\e963",
    icon-AddPhotos: "\e964",
    icon-CrossRounded: "\e965",
    icon-OLX: "\e968",
    icon-EyeOff: "\e969",
    icon-DoubleCheckRead: "\e96d",
    icon-attach: "\e96f",
    icon-offer: "\e971",
    icon-meeting: "\e972",
    icon-iconcategories: "\e973",
    icon-sorting: "\e975",
    icon-mobileFilters: "\e976",
    icon-bigView: "\e977",
    icon-gridView: "\e978",
    icon-listView: "\e979",
    icon-mic: "\e97a",
    icon-play: "\e97b",
    icon-pause: "\e97c",
    icon-myads: "\e97d",
    icon-unfollow-friend: "\e97e",
    icon-call: "\e981",
    icon-twotone-more1: "\e9da",
    icon-twotone-nearby: "\e9dc",
    icon-twotone-sale: "\e9dd",
    icon-twotone-rent: "\e9de",
    icon-YoutubeProfile: "\e9df",
    icon-TwitterProfile: "\e9e0",
    icon-InstagramProfile: "\e9e1",
    icon-Key: "\e982",
    icon-Inventory: "\e983",
    icon-Draft: "\e984",
    icon-Addcar: "\e985",
    icon-Lift: "\e93f",
    icon-CarParking: "\e986",
    icon-CCTV: "\e987",
    icon-ClubHouse: "\e988",
    icon-FireAlarm: "\e989",
    icon-Fitness: "\e98a",
    icon-Intercom: "\e98b",
    icon-PlayArea: "\e98c",
    icon-PowerBackup: "\e98d",
    icon-RainWater: "\e98e",
    icon-Security: "\e98f",
    icon-SwimmingPool: "\e990",
    icon-WaterSupply: "\e991",
    icon-twotone-motorbike: "\e9db",
    icon-AttentionHaired: "\e960",
    icon-AddFriend: "\e95c",
    icon-Filters: "\e91d",
    icon-help-outline: "\e901",
    icon-PremiumFeaturedAd: "\e90e"
);

@mixin fontSize($fontSize) {
    font-size: getFontSizes($fontSize);
    line-height: getLineHeight($fontSize);
}

@mixin resetUl {
    margin:0;
    padding:0;
    list-style: none;
}

@mixin ellipsis ($max-width){
    display: inline-block;
    max-width: $max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin padding($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        padding: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3) getSpacingValues($value4);
    } @else if $value1 and $value2 and $value3 {
        padding: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3);
    } @else if $value1 and $value2 {
        padding: getSpacingValues($value1) getSpacingValues($value2);
    } @else if $value1 {
        padding: getSpacingValues($value1);
    }
}

@mixin margin($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        margin: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3) getSpacingValues($value4);
    } @else if $value1 and $value2 and $value3 {
        margin: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3);
    } @else if $value1 and $value2 {
        margin: getSpacingValues($value1) getSpacingValues($value2);
    } @else if $value1 {
        margin: getSpacingValues($value1);
    }
}

@mixin padding4($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        padding: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3) getSpacingValue($value4);
    } @else if $value1 and $value2 and $value3 {
        padding: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3);
    } @else if $value1 and $value2 {
        padding: getSpacingValue($value1) getSpacingValue($value2);
    } @else if $value1 {
        padding: getSpacingValue($value1);
    }
}

@mixin margin4($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        margin: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3) getSpacingValue($value4);
    } @else if $value1 and $value2 and $value3 {
        margin: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3);
    } @else if $value1 and $value2 {
        margin: getSpacingValue($value1) getSpacingValue($value2);
    } @else if $value1 {
        margin: getSpacingValue($value1);
    }
}

@mixin card() {
    width: 100%;
    overflow : hidden;
    box-sizing: border-box;
    background: getNeutralColors(white);
    border: 1px solid getNeutralColors(blackDividers);
    border-radius: getSpacingValue(1);
}

@mixin cardContent() {
    @include padding(2, 3);
}

@mixin cardTitle() {
    @include fontSize(subtitle);
    font-weight: getFontWeights(bold);
    color: getNeutralColors(blackText);
    @include padding(1, 0);
}

@mixin cardSubTitle() {
    @include fontSize(body2);
    font-weight: getFontWeights(bold);
    color: getNeutralColors(blackText);
    @include padding(1, 0);
}

@mixin cardText() {
    @include fontSize(body1);
    margin: 0;
    @include padding(1, 0);
    font-weight: getFontWeights(regular);
    color: getNeutralColors(blackSecondaryText);
}

@mixin modal($width: 108, $height: null) {
    @include margin(0, 2);
    @include padding(2);
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: getNeutralColors(white);
    width: getSpacingValue($width);
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    @if $height {
        height: getSpacingValue($height);
    }
}

@mixin modalNew($width, $height) {
    @include modal($width, $height);
    @include padding4(4,5);
}

@mixin modalWide($width, $height) {
    @include modal($width, $height);
    @include padding(0);
}

@mixin fullPageModal() {
    width: 100%;
    padding: getSpacingValues(3);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: getNeutralColors(white);
    height: 100%;
    overflow: auto;
}

@mixin fullPageModalMxCl() {
    background-color: getNeutralColors(white);
    width: 100%;
    height: 100%;
    overflow: auto;
    @include padding4(0);
}

@mixin placeholder($duration: 1s, $fillMode: forwards, $iterationCount: infinite, $timingFunction: linear, $backgroundSize: 900px 104px, $position: relative ) {
    @keyframes placeHolderShimmer{
        0%{
            opacity: 0.3;
        }
        50%{
            opacity: 0.7;
        }
        100%{
            opacity: 0.3;
        }
    }

    animation-duration: $duration;
    animation-fill-mode: $fillMode;
    animation-iteration-count: $iterationCount;
    animation-name: placeHolderShimmer;
    animation-timing-function: $timingFunction;
    background: getNeutralColors(blackDividers);
    background-size: $backgroundSize;
    position: $position;
}

@mixin invertColorBlackWhite() {
    color: getNeutralColors(blackText);
}

@mixin invertColorPrimaryWhite() {
    color: getBrandColor(primary);
}

@mixin invertBackColorPrimaryWhite() {
    background-color: getThemeColors(headerBg);
}

@mixin link($fontSize: body1) {
    text-decoration: none;
    cursor: pointer;
    font-size: getFontSizes($fontSize);
    color: getNeutralColors(blackText);
}

@mixin flatLink($fontSize: 'body1') {
    @include link($fontSize);
    color: getBrandColor(primary);
    font-weight: getFontWeights(bold);
    text-transform: uppercase;
}

@mixin textLink($fontSize: 'body1') {
    @include link($fontSize);
    color: getBrandColor(primary);
    text-decoration: underline;
}

@mixin listLink($fontSize: 'body1') {
    @include link($fontSize);
    color: getNeutralColors(blackSecondaryText);
    &:hover {
        color: getNeutralColors(blackText);
    }
}

@mixin placeholder-chat-mixin($width: null, $height: null, $borderRadius: null, $position: relative) {
    width: $width;
    height: $height;
    display: inline-block;
    border-radius: $borderRadius;
    @include placeholder($position: $position);
}

@mixin notificationDot ($position: absolute, $size: 2) {
    position: $position;
    height: getSpacingValue($size);
    width: getSpacingValue($size);
    border-radius: 50%;
    background-color: getBrandColor(accent);
}

@mixin disableSelect () {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

@mixin flex ($flex-direction: row, $flex-wrap: nowrap, $align-item: center, $justify-content: center) {
    display: flex;
    flex-direction:$flex-direction;
    flex-wrap: $flex-wrap;
    align-items: $align-item;
    justify-content: $justify-content;
}

.gridItemCard, .bigItemCard, .listItemCard {
    width: 50%;
    position: relative;
    border-radius: 3px;
    box-sizing: border-box;

    .status_sponsor {
        background-color: getUtilityColors(pinToTopBadge);
    }

    .bottomCardInfo {
        display: flex;
        margin-top: auto;
        justify-content: space-between;
        text-transform: uppercase;
        color: getNeutralColors(blackSecondaryText);
        font-size: getFontSizes(caption);

        .date {
            text-transform: uppercase;
            margin-left: auto;
        }
    }

    > a {
        position: relative;
        text-decoration: none;
        @include card();

        overflow: hidden;
        border-radius: getSpacingValue(1);

        .videoOverDesktop{
            justify-content: center;
            display: flex; 
            align-items: center;           
            margin-left:getSpacingValues(1);
            margin-right:getSpacingValues(1) ;
            margin-top: getSpacingValues(-3.25);
            background-color: getRGBAColors(black50);
            backdrop-filter: blur(5px);
            height: getSpacingValues(2.5);
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: getFontSizes(caption);
            letter-spacing: 0.4px;
            color: getNeutralColors(white);

            .videoIconDesktop{
                display: flex;
                margin-right: getSpacingValues(.25);
            }
            
        }

        .dealerTag{
            @media only screen and (max-width: getBreakpoints(phablet)) {
                width: getSpacingValues(3.5);
                height: getSpacingValues(2);
                border-radius: getSpacingValues(0.25);
            }

            display: flex;
            width: getSpacingValues(6.5);
            height: getSpacingValues(3.5);
            border-radius: getSpacingValues(0.5);
        }

        .dealerContainer{
            position: absolute;

            @media only screen and (max-width: getBreakpoints(phablet)) {
                top: getSpacingValues(0.75);
                left: getSpacingValues(0.75);
            }

            top: getSpacingValues(1.5);
            left: getSpacingValues(1.5);
            z-index: 2;
        }


        .userTypeLogo {
            display: block;
            margin-right: getSpacingValue(2);
            width: getSpacingValue(9);
            height: getSpacingValues(2);
            border-radius: getSpacingValues(0.5);
        }


        > figure {
            position: relative;
            padding: 0;
            box-sizing: border-box;

            > img {
                max-width: 100%;
            }

            &::before {
                right: 5px;
                width: 50px;
                height: 50px;
                bottom: -25px;
                line-height: 35px;
                text-align: center;
                position: absolute;
                border-radius: 50%;
                color: getBrandColor(accent);
                background-color: getNeutralColors(white);
            }

            .blurry {
                filter: blur(5px);
            }

            noscript {
                img {
                    z-index: 1;
                }
            }
        }
       
        .resume {
            .title, .price, label {
                margin: 5px 0;
                position: relative;
                z-index: 2;
            }

            .price {
                margin-top: 0;
            }

            .title {
                color: getNeutralColors(blackSecondaryText);
                font-size: getFontSizes(body1);
            }

            .details {
                color: getNeutralColors(blackText);
                font-size: getFontSizes(body1);
                @include margin(0.25, 0, 0, 0);

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .price {
                /* rtl:ignore */
                direction:ltr;

                /* rtl:ignore */
                font-weight: bold;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: getNeutralColors(blackText);
                font-size: getFontSizes(subtitle);
            }

            .favorite {
                right: 0;
                bottom: 0;
                cursor: pointer;
                position: absolute;
                color: getNeutralColors(blackHintText);

                &::before {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                }
            }
        }

        &.new {
            > div {
                border-left: 5px solid getBrandColor(accent);

                label {
                    color: getBrandColor(accent);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getBrandColor(accent);
                }
            }
        }

        &.hot {
            > div {
                border-left: 5px solid getBrandColor(accent);

                label {
                    color: getBrandColor(accent);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getBrandColor(accent);
                }
            }
        }

        &.sold {
            > div {
                border-left: 5px solid getBrandColor(accent);

                label {
                    color: getBrandColor(accent);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getBrandColor(accent);
                }
            }
        }

        &.outdated {
            > div {
                border-left: 5px solid getNeutralColors(blackText);

                label {
                    color: getNeutralColors(blackText);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getNeutralColors(blackText);
                }
            }
        }

        &.rejected {
            > div {
                border-left: 5px solid getUtilityColors(error);

                label {
                    color: getUtilityColors(error);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getUtilityColors(error);
                }
            }
        }

        &.deleted {
            > div {
                border-left: 5px solid getUtilityColors(error);

                label {
                    color: getUtilityColors(error);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getUtilityColors(error);
                }
            }
        }

        &.modify {
            > div {
                border-left: 5px solid getUtilityColors(error);

                label {
                    color: getUtilityColors(error);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getUtilityColors(error);
                }
            }
        }

        &.pending {
            > div {
                border-left: 5px solid getNeutralColors(blackSecondaryText);

                label {
                    color: getNeutralColors(blackSecondaryText);
                    text-transform: uppercase;

                    > span {
                        font-size: getFontSizes(footnote);
                    }
                }

                i {
                    color: getNeutralColors(blackSecondaryText);
                }
            }
        }
    }

    .location {
        color: getNeutralColors(blackSecondaryText);
        display: inline-block;
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.favIcon {
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
}

.favIconOlx {
    top: getSpacingValues(1.5);
    right: getSpacingValues(1.5);
}

.favIconOlxMob {
    top: getSpacingValues(0.5);
    right: getSpacingValues(0.5);
}

.favIconBtn {
    width: getSpacingValues(4.5);
    height: getSpacingValues(4.5);
    border-radius: 50%;
    background: white;
    @media only screen and (max-width: getBreakpoints(phablet)) {
        width: getSpacingValues(4);
        height: getSpacingValues(4);
    }
}

.gridViewInspectedIcon {
    position: absolute;
    right: getSpacingValues(1.5);
    top: getSpacingValues(17.25);
    z-index: 4;
    
    &.desktopFeedItemCard {
        width: 30px !important;
        z-index: 0;
    }

    &.mobileFeedItemCard {
        top: getSpacingValues(12);
    }

    .carInspectedImage {
        width: getSpacingValues(3.75);
        height: getSpacingValues(3.75);
    }
}

.gridItemCard {
    @include margin(1);

    padding: 0;
    text-align: left;

    > a {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;       

        > figure {
            @include margin(1);

            overflow: hidden;           
        }

        .resume {
            display: flex;
            flex-direction: column;
            flex: 1;
            position: relative;
            @include padding(1, 2);
            @media only screen and (max-width: getBreakpoints(phablet)-1) {
                .bottomCardInfo {
                    .date {
                        display: none;
                    }
                }
            }

            > label {
                font-size: getFontSizes(body1);

                &::before {
                    margin-top: -5px;
                    font-size: getFontSizes(body2);
                }
            }

            .status {
                display: initial;
            }

            .status_sponsor {
                background-color: getUtilityColors(pinToTopBadge);
            }

            .title, .price, .details, label {
                text-align: left;
                display: block;
            }

            .title {
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 5px;

                &.mainTitle {
                    margin-top: getSpacingValues(1);
                }
            }

            .statistics {
                list-style: none;
                margin-top: 10px;
                height: 24px;
                padding: 9px 8px 0 15px;
                border-top: 1px solid getNeutralColors(blackDividers);
                color: getNeutralColors(blackHintText);
                display: flex;
                align-items: center;
                justify-content: flex-start;

                > div {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    height: 100%;

                    &:last-child {
                        margin-right: 0;
                    }

                    i {
                        margin-right: 5px;
                    }

                    span {
                        font-size: getFontSizes(footnote);
                    }

                    &.views {
                        i::before {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        &.statusTags, &.sold {
            > div {
                position: static;

                label {
                    position: absolute;
                    top: 56%;
                    left: getSpacingValues(1);
                    text-transform: uppercase;
                    padding: 0;
                    display: flex;
                    align-items: flex-start;

                    .olxAutosTag{
                        @include margin4(0,1,0,0);
                        @media only screen and (min-width: getBreakpoints(phablet)) {
                            vertical-align: middle;
                        }

                        margin-left: getSpacingValues(0.75);
                        margin-bottom: getSpacingValue(0.25);
                        display: flex;
                    }

                    .otoPlus {
                        position: absolute;
                        bottom: getSpacingValues(1);
                    }

                    > span {
                        font-size: getFontSizes(caption);
                        padding: getSpacingValues(0.375) getSpacingValues(1);
                        font-weight: getFontWeights(bold);
                        display: block;
                        margin-left: getSpacingValues(0.75);
                    }

                }
            }
        }

        &.sold {
            > div {
                border-left: 5px solid getBrandColor(accent);

                label {
                    > span {
                        background-color: getBrandColor(accent);
                        color: getNeutralColors(blackText);
                    }
                }
            }
        }

        &.statusTags {
            > div {
                border-left: 5px solid getUtilityColors(monetization);

                label {

                    @media only screen and (max-width: getBreakpoints(phablet)) {
                        left: getSpacingValues(1.5);
                        top: 58%;
                    }

                    > span {
                        border-radius: getSpacingValues(0.5);

                        &.olx {
                            border-radius: getSpacingValues(0.25);
                        }

                        @media only screen and (max-width: getBreakpoints(phablet)) {
                            @include padding4(0.375,1);
                            
                        }

                        > img {
                            margin-right: getSpacingValues(1);
                        }

                        background-color: getUtilityColors(monetization);
                        color: getNeutralColors(blackText);
                        letter-spacing: getSpacingValues(0.0688);
                        font-weight: getFontWeights(regular);
                        display: flex;

                    }

                }   
            }           
        }
    }
}

.bigViewInspectedIcon {
    position: absolute;
    bottom: getSpacingValues(11.25);
    right: getSpacingValues(2.5);
    z-index: 7;

    .carInspectedImage {
        width: getSpacingValues(5);
        height: getSpacingValues(5);
    }
}

.bigItemCard {
    margin: 7px 0;
    padding: 0 7px;

    > a {
        width: 100%;
        display: inline-block;

        > figure {
            margin: 0;
            overflow: hidden;
            max-height: 357px;
            height: 357px;
        }

        .resume {
            display: flex;
            flex-direction: column;
            position: relative;
            @include padding(1,2);

            > label {
                font-size: getFontSizes(body1);

                &::before {
                    margin-top: -5px;
                    font-size: getFontSizes(body2);
                }
            }

            .title, .price, .details, label {
                display: block;
            }

            .title {
                @include margin(0.5, 0, 1, 0);

                hyphens: auto;
                font-weight: 400;
                word-wrap: break-word;
                overflow-wrap: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.mainTitle {
                    margin-top: getSpacingValues(1);
                }
            }

            .statistics {
                list-style: none;
                margin: 10px 0 0;
                height: 24px;
                padding: 9px 8px 0 15px;
                border-top: 1px solid getNeutralColors(blackDividers);
                color: getNeutralColors(blackHintText);

                li {
                    float: left;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    i {
                        margin-right: 5px;
                    }

                    span {
                        font-size: getFontSizes(footnote);
                    }

                    &.favorites {
                        i::before {
                            font-size: 20px;
                        }
                    }

                    &.views {
                        i::before {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        &.statusTags {
            > div {
                position: static;
                border-left: 5px solid getUtilityColors(monetization);

                label {
                    text-transform: uppercase;
                    position: absolute;
                    top: getSpacingValues(1);
                    left: getSpacingValues(1);
                    padding: 0;

                    > span {
                        background-color: getUtilityColors(monetization);
                        color: getNeutralColors(blackText);
                        font-size: getFontSizes(caption);
                        padding: 5px 12px;
                        font-weight: getFontWeights(bold);
                    }
                }
            }
        }
    }
}

.listViewInspectedIcon {
    position: absolute;
    bottom: getSpacingValues(-2);
    left: getSpacingValues(1.25);
    z-index: 7;

    .carInspectedImage {
        width: getSpacingValues(3.75);
        height: getSpacingValues(3.75);
    }
}

.listItemCard {
    margin: 7px 0;
    padding: 0 7px;

    > a {
        position: relative;
        width: 100%;
        height: 187px;
        display: flex;

        .videoOverMobile{
            position: absolute;
            bottom: 0;
            width: getSpacingValue(32.5);
            background-color: getRGBAColors(black50);
            height: getSpacingValues(2.25);
            backdrop-filter: blur(5px);
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            z-index: 2;
            font-size: getFontSizes(caption);
            letter-spacing: 0.4px;
            color: getNeutralColors(white);
            @media only screen and (min-width: getBreakpoints(phablet)) {
                width: getSpacingValue(68);
            }
            
            .videoIconMobile{
                display: flex;
                margin-right: getSpacingValues(.5);
        }
        }

        > figure {
            width: 272px;
            min-width: 272px;
            order: 1;
            height: 185px;
            position: relative;
            z-index: 1;

            > img {
                margin: auto;
            }

        }

        .resume {
            display: flex;
            flex-direction: column;
            -ms-flex-order: 2;
            order: 2;
            overflow: hidden;
            width: 100%;
            @include padding(3, 2, 1, 2);

            & > *:first-child {
                /* 40px -> Button width */
                max-width: calc(100% - 40px);

                @media only screen and (max-width: getBreakpoints(phablet)) {
                    max-width: calc(100% - 20px);
                }
            }

            .bottomCardInfo {
                margin-top: auto;
            }

            .title {
                hyphens: auto;
                word-wrap: break-word;
                overflow-wrap: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            .price {
                display: block;
            }

            .statistics {
                list-style: none;
                margin: 10px 0 0;
                height: 24px;
                padding: 9px 8px 0 15px;
                border-top: 1px solid getNeutralColors(blackDividers);
                color: getNeutralColors(blackHintText);

                li {
                    float: left;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    i {
                        margin-right: 5px;
                    }

                    span {
                        font-size: getFontSizes(footnote);
                    }

                    &.favorites {
                        i::before {
                            font-size: 20px;
                        }
                    }

                    &.views {
                        i::before {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        &.statusTags {
            > div {
                position: relative;
                border-left: 5px solid getUtilityColors(monetization);

                label {
                    display: flex;
                    align-items: flex-start;
                    text-transform: uppercase;

                    > picture {
                        margin-right: getSpacingValues(1);
                    }

                    > span {
                        background-color: getUtilityColors(monetization);
                        color: getNeutralColors(blackText);
                        font-size: getFontSizes(footnote);
                        padding: 3px 9px 2px;
                        margin-right: getSpacingValues(0.75);
                        display: flex;
                        align-items: flex-start;
                        width: fit-content;
                        flex-wrap: wrap;

                        @media only screen and (max-width: getBreakpoints(phablet)) {
                            vertical-align: text-top;
                            border-radius: getSpacingValues(0.25);
                            @include padding4(0.5,1);

                            font-size: getFontSizes(caption);
                            
                        }

                        > img {
                            margin-right: getSpacingValues(1);
                            width: getSpacingValues(1.5);
                            vertical-align: middle;

                            @media only screen and (max-width: getBreakpoints(phablet)) {
                                vertical-align: initial;
                            }
                        }
                    }

                    .status_sponsor {
                        background-color: getUtilityColors(pinToTopBadge);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: getBreakpoints(desktop)){
    .gridItemCard {
        width: 25%;
    }
}

@media only screen and (min-width: getBreakpoints(phablet)){
    .bigItemCard {
        .resume {
            height: 85px;
        }
    }
}

@media only screen and (min-width: getBreakpoints(desktop)){
    .gridItemCard, .listItemCard, .bigItemCard {
        .bottomCardInfo {
            font-size: getFontSizes(caption);
        }
    }

    .bigItemCard {
        .mainTitle {
            max-width: 95%;
            @include margin(1, 0, 0, 0);
        }
    }

    .listItemCard {
        > a {
            .resume {
                .details {
                    @include margin(1, 0, 0, 0);
                }

                .title {
                    @include margin(2, 0, 0, 0);

                    &.mainTitle {
                        max-width: 95%;
                        @include margin(1, 0, 0, 0);
                    }
                }
            }
        }
    }

    .location {
        text-transform: uppercase;
    }

    .gridItemCard.gridItemCard {
        height: 266px;
    }

    .gridItemCard {
        .location {
            max-width: 75%;
        }

        width: 25%;
    }

    .bigItemCard > a .resume .title {
        @include margin(0.25, 0, 1.25, 0);
    }
}

@media only screen and (max-width: getBreakpoints(phablet)-1) {
    .listItemCard, .bigItemCard, .gridItemCard {
        a {
            .resume {
                .title {
                    font-size: getFontSizes(footnote);
                }
            }
        }
    }

    .listItemCard {
        .bottomCardInfo {
            text-transform: uppercase;
            font-size: getFontSizes(caption);
        }

        .location {
            max-width: 60%;
        }

        a {
            height: 130px;

            figure {
                height: 130px;
                width: 130px;
                min-width: 130px;
            }

            .resume {
                @include padding(1,2);

                .title.mainTitle {
                    margin-top: getSpacingValues(1);
                }
            }
        }
    }

    .bigItemCard {
        display: flex;
        position: relative;
        flex-direction: column;

        a  {
            figure {
                height: 200px;
            }

            .resume {
                padding: getSpacingValues(1) getSpacingValues(2);
                height: 85px;
                display: flex;
                flex-direction: column;

                .title {
                    @include margin(0.25, 0, 1.5, 0);

                    &.mainTitle {
                        margin-top: getSpacingValues(1);
                    }
                }
            }
        }
    }

    .gridItemCard.gridItemCard {
        height: getSpacingValues(33);

        .location {
            max-width: unset;
        }

        .resume {
            padding: getSpacingValues(1);
        }
    }

    .location {
        font-size: getFontSizes(caption);
    }
}
